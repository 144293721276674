import { useCallback, useRef } from "react";

import { Box, Button } from "@chakra-ui/react";
import Router from "next/router";
import Script from "next/script";
import { useSWRConfig } from "swr";

import { useAccount } from "@/hooks/useAccount";
import { useSignOut } from "@/hooks/useSignOut";
import * as fetchJSON from "@/lib/fetchJSON";

const FORM_ID = process.env.NEXT_PUBLIC_TALLY_ONBOARDING_FORM_ID;

export function OnboardingModal() {
  const account = useAccount();
  const isOnboardedRef = useRef<boolean>(account.is_onboarded);
  const { signOut } = useSignOut();
  const { mutate } = useSWRConfig();
  const handleSubmit = useCallback(
    async (payload: any) => {
      await fetchJSON.http.post("/api/tally/submissions/onboarding", payload);
      isOnboardedRef.current = true;
      (window as any).Tally.closePopup(FORM_ID);
    },
    [isOnboardedRef]
  );
  const openPopup = useCallback(async () => {
    if (!isOnboardedRef.current) {
      (window as any).Tally.openPopup(FORM_ID, {
        customFormUrl: "https://forms.timbiz.dev/create-account",
        layout: "modal",
        onClose: () => {
          if (isOnboardedRef.current) {
            Router.replace({ query: { welcome: true } });
            mutate("/api/accounts");
          } else {
            openPopup();
          }
        },
        onSubmit: handleSubmit,
        overlay: true,
        width: 800,
      });
    }
  }, [handleSubmit, isOnboardedRef, mutate]);

  return isOnboardedRef.current ? null : (
    <>
      <Box pos="fixed" right={10} top={10} zIndex={100000006}>
        <Button onClick={signOut} size="sm">
          Sign out
        </Button>
      </Box>
      <Script onLoad={openPopup} src="https://tally.so/widgets/embed.js" />
    </>
  );
}
