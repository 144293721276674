import { PageHeader } from "@/components/PageHeader";

import type { PageHeaderProps } from "@/components/PageHeader";

export function IntegrationsPageHeader(props: Pick<PageHeaderProps, "tab">) {
  return (
    <PageHeader
      {...props}
      navItems={[
        { id: "bank", href: "/integrations/bank", label: "Bank" },
        {
          id: "accounting",
          href: "/integrations/accounting",
          label: "Accounting",
        },
        {
          id: "loan",
          href: "/integrations/loan",
          label: "Loan Data",
        },
      ]}
      pageSubtitle="Setup integrations so Level can provide debt offers"
      pageTitle="Integrations"
    />
  );
}
