import { useEffect } from "react";

import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useRouter } from "next/router";

import { IntegrationsPageHeader } from "@/components/IntegrationsPageHeader";
import { Loading } from "@/components/Loading";
import { OnboardingModal } from "@/components/OnboardingModal";
import { useAccount } from "@/hooks/useAccount";

function HomePage() {
  const account = useAccount();
  const router = useRouter();
  const isWelcome = !!router.query.welcome;

  useEffect(() => {
    if (!isWelcome && account.is_onboarded) {
      router.replace("/integrations/bank");
    }
  }, [account, isWelcome, router]);

  if (isWelcome) {
    return (
      <Flex w="full" h="full" justify="center" mt={24} align="center">
        <Box>
          <Flex justify="center" direction="column">
            <Heading mb="5" textAlign="center">{`Welcome to Level`}</Heading>
            <Heading color="gray" mb="10" size="sm" textAlign="center">
              Next, share your bank & accounting data
            </Heading>
            <Button
              onClick={() => {
                router.replace("/integrations/bank");
              }}
            >
              Continue
            </Button>
          </Flex>
        </Box>
      </Flex>
    );
  }

  return (
    <>
      {!isWelcome && <Loading />}
      <OnboardingModal />
    </>
  );
}

export default HomePage;

HomePage.pageHeader = <IntegrationsPageHeader tab="bank" />;
HomePage.requiresAuth = true;
