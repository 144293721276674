import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import NextLink from "next/link";

interface NavigationItem {
  id: string;
  href: string;
  label: string;
}

export interface PageHeaderProps {
  navItems?: NavigationItem[];
  pageSubtitle: string;
  pageTitle: string;
  tab?: string;
}

export function PageHeader(props: PageHeaderProps) {
  const { navItems, pageSubtitle, pageTitle, tab } = props;

  return (
    <>
      <Box as="header" px={8} minWidth={520} w="full">
        <Heading as="h1" mb={2} mt={3} size="lg">
          {pageTitle}
        </Heading>
        <Text color="GrayText" fontSize="med">
          {pageSubtitle}
        </Text>
      </Box>

      {navItems && (
        <Box
          as="nav"
          aria-label="Component navigation"
          minWidth={520}
          mt={4}
          px={8}
        >
          <HStack as="ul" listStyleType="none" borderBottomWidth="1px">
            {navItems.map((item) => (
              <Box as="li" key={item.id}>
                <NextLink href={item.href} passHref replace>
                  <Box
                    mb="-1px"
                    as="a"
                    color="gray.500"
                    display="block"
                    fontSize="lg"
                    fontWeight={700}
                    px="5"
                    py="1"
                    borderBottom="2px solid transparent"
                    data-selected={tab === item.id ? "" : undefined}
                    _selected={{
                      color: "blue.500",
                      borderColor: "gray.500",
                    }}
                  >
                    {item.label}
                  </Box>
                </NextLink>
              </Box>
            ))}
          </HStack>
        </Box>
      )}
    </>
  );
}
